var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c("inn-breadcrumbs", { attrs: { items: _setup.breadcrumbs } }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("header", { staticClass: "app-welcome-message" }, [
                  _c("h1", [_vm._v("Overzicht van meters")]),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: {
                        dark: "",
                        to: {
                          name: "EU-RegisterAdd",
                          params: { userId: "-", addModus: true },
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", left: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _vm._v(" Meter toevoegen "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("header", { staticClass: "app-welcome-message" }, [
                  _c("p", [
                    _vm._v("In dit overzicht worden alle meters getoond."),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "overview-wrapper" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c(_setup.RegisterOverview)],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }